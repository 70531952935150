
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserOrderItem } from '@/models/UserOrderItem'

@Component({
  components: {}
})
export default class OrderItemList extends Vue {
  @Prop()
  orderItems: UserOrderItem[]
}

import { UserOrder } from '@/models/UserOrder'

export class UserOrderConverter
  implements firebase.firestore.FirestoreDataConverter<UserOrder> {
  toFirestore(order: UserOrder): firebase.firestore.DocumentData {
    return {
      state: order.state
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const order = new UserOrder(snapshot.ref)

    order.number = data.number
    order.total = data.total
    order.state = data.state
    order.createdAt = data.created_at.toDate()
    if (data.fulfilled_at) {
      order.fulfilledAt = data.fulfilled_at.toDate()
    }
    order.paymentIntent = data.payment_intent
    order.invoiceNumber = data.invoice_number
    order.session = data.session
    return order
  }
}












import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class OrderNumber extends Vue { }

import { Model } from '@/models/Model'
import { UserOrderState } from '@/models/Types'
import { formatMoney } from '@/services/NumberService'

class UserOrder extends Model {
  total: number

  get formattedSubtotal(): string {
    return this.session ? formatMoney(this.session.amount_subtotal) : null
  }

  get formattedTotal(): string {
    return formatMoney(this.session ? this.session.amount_subtotal : this.total)
  }

  number: string
  state: UserOrderState
  createdAt: Date
  fulfilledAt: Date
  paymentIntent: any
  invoiceNumber: string
  session: any

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { UserOrder }

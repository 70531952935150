





























































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Notification from '@/components/Notification.vue'
import Button from '@/components/Button.vue'
import Loading from '@/components/Loading.vue'
import Dialog from '@/components/Dialog.vue'
import Content from '@/components/Content.vue'
import OrderItemList from '@/components/orders/OrderItemList.vue'
import OrderNumber from '@/components/orders/OrderNumber.vue'
import { UserOrder } from '@/models/UserOrder'
import { UserOrderItem } from '@/models/UserOrderItem'
import { firestore } from 'firebase/app'
import { UserOrderConverter } from '@/models/converters/UserOrderConverter'
import { UserOrderItemConverter } from '@/models/converters/UserOrderItemConverter'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {
    Notification,
    Header,
    Button,
    Loading,
    Dialog,
    Content,
    OrderItemList,
    OrderNumber
  }
})
export default class Account extends mixins(UserMixin) {
  loading: boolean = true

  orders: UserOrder[]
  orderItems: { [id: string]: UserOrderItem[] } = {}

  async created() {
    this.orders = (
      await firestore()
        .collection('users')
        .doc(this.user.id)
        .collection('orders')
        .withConverter(new UserOrderConverter())
        .orderBy('created_at', 'desc')
        .get()
    ).docs.map((doc) => doc.data())

    for (const order of this.orders) {
      const orderItems = (
        await firestore()
          .collection('users')
          .doc(this.user.id)
          .collection('orders')
          .doc(order.id)
          .collection('items')
          .withConverter(new UserOrderItemConverter())
          .get()
      ).docs.map((doc) => doc.data())

      for (const orderItem of orderItems) {
        await orderItem.populate()
      }

      Vue.set(this.orderItems, order.id, orderItems)
    }
    this.loading = false
  }

  isFulfilled(order: UserOrder) {
    return order.state === 'fulfilled'
  }
}

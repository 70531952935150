











import { Component, Prop, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class Content extends Vue {
  @Prop()
  loading: boolean
}
